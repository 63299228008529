import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { compose, Dispatch } from 'redux';
import { Container, Alert, Button } from 'reactstrap';

import Spinner from '@/components/elements/Spinner';
import Stepper from '@/components/elements/Stepper';

// Partials
import Company from '@/components/partials/Company';

import { ConfigKeys } from '@/misc/constants';
import { CompanyStateType, Configuration, ServicesStateType, BookingStateType, ServiceType } from '@/types';
import { RouteComponentProps, withRouter } from 'react-router';
import { Trans } from '@lingui/macro';
import initGTM from '@/misc/initGTM';
import { fixLoadingTooLong } from '@/actions';
import { ApplicationState } from '@/store';


const GTM_TAG_ID = 'GTM-TNJPS6G';
const POLLING_INTERVAL = 1000;
const MAX_TOO_LONG_TIME = 10000;

interface Props extends PropsFromRedux, RouteComponentProps<{ articleId?: string, serviceId?: string}> { }


export const MainLayout: React.FC<Props> = (props) => {
    const { selectedService ,services, company, authentication , configuration, children, dispatch, history, embed } = props;
    const [gtmInitialized, setGtmInitialized] = useState(false);
    const [tooLongLoading, setTooLongLoading] = useState(false);

    const pathname = props.location.pathname;
    const showLoading = services.isLoading || !company;
    const themeIsLoaded = !!company;

    const targetOrigin = configuration?.targetOrigin
      ? configuration?.targetOrigin
      : configuration?._targetOrigin;

    useEffect(() => {
      if (configuration.analytics) {
        if (!gtmInitialized && targetOrigin && company) {
          console.log("gtm", GTM_TAG_ID);
          initGTM(GTM_TAG_ID, targetOrigin);
          setGtmInitialized(true);
        } else if (!gtmInitialized && company?.Homepage) {
          console.log("gtm", GTM_TAG_ID);
          initGTM(GTM_TAG_ID, company?.Homepage);
          setGtmInitialized(true);
        }
      }
    }, [company, configuration]);

    useEffect(() => {
        // Widget embedding parameter can override settings from admin panel
        let shouldUseDarkTheme = company?.WidgetSettings?.DarkTheme;
        if (typeof configuration.darkTheme !== 'undefined') {
            shouldUseDarkTheme = configuration.darkTheme;
        }

        let primaryColor = company?.WidgetSettings?.PrimaryColor;
        if (typeof configuration.primaryColor !== 'undefined') {
            primaryColor = configuration.primaryColor;
        }
        if (primaryColor) {
            const root = document.documentElement;
            root.style.setProperty('--bokamera-primary', primaryColor);
            root.style.setProperty('--bokamera-primary-active', primaryColor);
            root.style.setProperty('--bokamera-primary', primaryColor);
        }
        if (shouldUseDarkTheme) {
            const root = document.documentElement;
            root.setAttribute('data-theme', 'dark');
        }
        // A block to uncomment in case of  dark theme development
        if (false) {
            const root = document.documentElement;
            root.setAttribute('data-theme', 'dark');
            root.style.setProperty('background-color', '#22292f');
        }
    }, [company]);

    useEffect(() => {
      let time = 0;
      
      const intervalId = setInterval(() => {
        time += POLLING_INTERVAL;
        
        if(time > MAX_TOO_LONG_TIME) {
          setTooLongLoading(true);
          clearInterval(intervalId);
        }
      }, POLLING_INTERVAL);

      if(!showLoading) {
        time = 0;
        clearInterval(intervalId);
        setTooLongLoading(false);
      }

      return () => {
        clearInterval(intervalId);
        setTooLongLoading(false);
      }
    }, [showLoading])



    let activeTab = 0;

    if (pathname === '/' && !configuration.selectedService) {
        activeTab = 0;
    } else if (pathname.endsWith('/times') && !configuration.selectedService) {
        activeTab = 1;
    } else if (pathname.endsWith('/confirm') && !configuration.selectedService) {
        activeTab = 2;
    }

    else if(pathname === '/times' && configuration.selectedService) {
        activeTab = 0;
    } else if (pathname.endsWith('/confirm') && configuration.selectedService) {
        activeTab = 1;
    }

    if (embed.allowEmbed === false) {
      return (
        <Container fluid>
          <Alert color={embed.type}>{embed.message}</Alert>
        </Container>
      );
    }

    if (!configuration) return <p>Loading</p>;
    if (!configuration.company || configuration.company.length === 0) {
        return (
            <Container fluid>
                <Alert color="danger">
                    <Trans id="noConfiguration"></Trans>
                </Alert>
            </Container>
        );
    }

    return (
      <div className="container" style={{ maxWidth: 960 }}>
        {!pathname.endsWith("/completed") &&
          configuration[ConfigKeys.SHOW_COMPANY_SUMMARY] && (
            <Company showPanel />
          )}
        {!pathname.endsWith("/completed") && (
          <>
            {!configuration.selectedService &&
            (configuration.bookLayout ===
              ConfigKeys.BOOK_LAYOUT_SERVICE_BASED ||
              configuration.bookLayout ===
                ConfigKeys.BOOK_LAYOUT_RESOURCE_BASED) ? (
              <Stepper
                steps={[
                  {
                    label: <Trans id="service"></Trans>,
                    onClick: () => history.push("/"),
                  },
                  {
                    label: <Trans id="time"></Trans>,
                    disabled: (/\/\articles\/(\d+)\/confirm/).test(pathname),
                    onClick: () => {
                      history.replace(`/services/${selectedService?.Id}/times`);
                      dispatch({
                        type: "SELECT_SERVICE",
                        payload: selectedService?.Id
                      });
                    },
                  },
                  {
                    label: <Trans id="finish"></Trans>,
                  },
                ]}
                activeStep={activeTab}
                noColor={!themeIsLoaded}
              />
            ) : !configuration.selectedService &&
              configuration.bookLayout === ConfigKeys.BOOK_LAYOUT_TIME_BASED ? (
              <Stepper
                steps={[
                  {
                    label: <Trans id="time"></Trans>,
                    onClick: () => history.push("/"),
                  },
                  {
                    label: <Trans id="finish"></Trans>,
                  },
                ]}
                activeStep={activeTab}
              />
            ) : ConfigKeys.BOOK_LAYOUT_RESOURCE_BASED ? (
              <Stepper
                steps={[
                  {
                    label: <Trans id="time"></Trans>,
                    onClick: () => history.push("/"),
                  },
                  {
                    label: <Trans id="finish"></Trans>,
                  },
                ]}
                activeStep={activeTab}
              />
            ) : null}
          </>
        )}

        {showLoading ? (
          <Spinner noGraceTime noColor={!themeIsLoaded}>
            <div>
              <Trans id="loadingServices" />
              ...
            </div>
          </Spinner>
        ) : (
          children
        )}

        {tooLongLoading || services.hasError || authentication?.error ? (
          <div className='text-center'>
            <Button
              className='mt-3'
              color="primary"
              onClick={() => {
                props.dispatch(fixLoadingTooLong.request());
              }}
            >
              <Trans id="loading.tooLong" />
            </Button>
          </div>
        ) : null}
      </div>
    );
};

// export the connected class
function mapStateToProps(state: ApplicationState) {
    return {
        embed: state.embed.entity,
        selectedService: state.booking?.service,
        authentication: state.authenticate,
        company: state.company.data,
        configuration: state.configuration.data,
        services: state.services || [],
        booking: state.booking || {},
        times: state.times || []
    };
}

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>

export default compose(
  connector,
  withRouter,
)(MainLayout) as React.ComponentType<any>;

import { createSlice, createAction } from "@reduxjs/toolkit";

type MessageType = 'info' | 'warning' | 'danger';
interface InitialState {
    entity: {
        allowEmbed: boolean;
        message?: string;
        type?: MessageType;
    }
}

export const preventFreeAccountEmbedding = createAction('embed/prevent', ({ message, type }: { message: string, type?: MessageType }) => {
    return {
        payload: {
            message,
            type
        }
    }
});

export const initialState: InitialState = {
    entity: {
        allowEmbed: true
    }
} 

const embedSlice = createSlice({
  name: "embed",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addMatcher(preventFreeAccountEmbedding.match, (state, action) => {
      state.entity.allowEmbed = false;
      state.entity.message = action.payload.message;
      state.entity.type = action.payload.type;
    });
  },
});

export default embedSlice;